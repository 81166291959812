import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import "./TermsModal.css"; // Custom styles for the terms modal
import Terms from "./Terms";

const TermsModal = ({ onClose }) => {
  return (
    <div className="terms-modal">
      <div className="terms-modal-content">
        <button className="close-button" onClick={onClose}>
          <IoCloseOutline size={24} />
        </button>
        <h3>Terms and Conditions</h3>
        <div className="terms-text-container">
          <Terms />
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
