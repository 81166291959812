import React from "react";
import "./Terms.css"; // CSS file for styling the component

const Terms = () => {
  return (
    <div className="terms-container">
      {/* Introduction Section */}
      <div className="terms-section">
        <p className="terms-paragraph">
          These are the terms and conditions. Please read them carefully before
          proceeding. By accepting these terms, you agree to follow the rules
          and guidelines.
        </p>
      </div>

      {/* Heading for Karsho Terms of Service */}
      <div className="terms-section">
        <h2 className="terms-heading">Karsho Terms of Service</h2>
        <p className="terms-paragraph">
          These terms of service constitute a legally binding agreement (the
          “Agreement”) between you and Karsho LLC (“Karsho,” “we,” “us” or
          “our”) governing your use of the Karsho application, website, and
          technology platform (collectively, the “Karsho Platform”).
        </p>
      </div>

      {/* Dispute Resolution Warning */}
      <div className="terms-section">
        <h3 className="terms-subheading">PLEASE BE ADVISED:</h3>
        <p className="terms-paragraph">
          THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS YOU AND
          KARSHO HAVE AGAINST EACH OTHER CAN BE BROUGHT (SEE SECTION 17 BELOW).
          THESE PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT
          CLAIMS YOU HAVE AGAINST KARSHO TO BINDING AND FINAL ARBITRATION ON AN
          INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS,
          GROUP OR REPRESENTATIVE ACTION OR PROCEEDING. AS A VENUE, YOU HAVE AN
          OPPORTUNITY TO OPT OUT OF ARBITRATION WITH RESPECT TO CERTAIN CLAIMS
          AS PROVIDED IN SECTION 17.
        </p>
      </div>

      {/* Agreement Acceptance */}
      <div className="terms-section">
        <p className="terms-paragraph">
          By entering into this Agreement, you expressly acknowledge that you
          understand this Agreement (including the dispute resolution and
          arbitration provisions in Section 17) and accept all of its terms. IF
          YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
          AGREEMENT, YOU MAY NOT USE OR ACCESS THE KARSHO PLATFORM.
        </p>
      </div>

      {/* The Karsho Platform Section */}
      <div className="terms-section">
        <h3 className="terms-subheading">The Karsho Platform</h3>
        <p className="terms-paragraph">
          The Karsho Platform provides a marketplace where persons who seek the
          ability to valet or showcase their vehicle (“Subscribers”) at certain
          destinations can be matched with various entities, destinations, car
          clubs, or events with the ability to showcase vehicles (“Venues”).
          Venues and Subscribers are collectively referred to herein as “Users,”
          and each User shall create a User account that enables access to the
          Karsho Platform. For purposes of this Agreement, the reservation
          services provided by the application to secure showcasing or valet at
          participating third-party Venues shall be referred to collectively as
          the “Services.”
        </p>
      </div>
      <div className="terms-section">
        <h3 className="terms-subheading">Modification to the Agreement</h3>
        <p className="terms-paragraph">
          In the event Karsho modifies the terms and conditions of this
          Agreement, such modifications shall be binding on you only upon your
          acceptance of the modified Agreement. Karsho reserves the right to
          modify any information referenced in the hyperlinks from this
          Agreement from time to time, and such modifications shall become
          effective upon posting. Continued use of the Karsho Platform or
          Services after any such changes shall constitute your consent to such
          changes. Unless material changes are made to the arbitration
          provisions herein, you agree that modification of this Agreement does
          not create a renewed opportunity to opt out of arbitration (if
          applicable).
        </p>
      </div>

      <div className="terms-section">
        <h3 className="terms-subheading">Eligibility</h3>
        <p className="terms-paragraph">
          The Karsho Platform may only be used by individuals who can form
          legally binding contracts under applicable law. The Karsho Platform is
          not available to children (persons under the age of 18), persons under
          the age of 21, or Users who have had their User account temporarily or
          permanently deactivated. By becoming a User, you represent and warrant
          that you are at least 21 years old and that you have the right,
          authority, and capacity to enter into and abide by the terms and
          conditions of this Agreement. You may not allow other persons to use
          your User account, and you agree that you are the sole authorized user
          of your account.
        </p>
      </div>
      <div className="terms-section">
  <h3 className="terms-subheading">Your Information</h3>
  <p className="terms-paragraph">
    Your Information is any information you provide, publish, or post to or
    through the Karsho Platform (including any profile information you provide)
    or send to other Users (including via in-application feedback, any email
    feature, or through any Karsho-related social media posting). You consent
    to us using your Information to create a User account that will allow you to
    use the Karsho Platform and participate in the Services. You are solely responsible for your Information and your interactions with other members of the public, and we act only as a passive conduit for 
    your online posting of your Information.
  </p>
  {/* <p className="terms-paragraph">
    Your Information is any information you provide, publish, or post to or
    through the Karsho Platform (including any profile information you provide)
    or send to other Users (including via in-application feedback, any email
    feature, or through any Karsho-related social media posting). You consent
    to us using your Information to create a User account that will allow you to
    use the Karsho Platform and participate in the Services. Our collection and
    use of personal information in connection with the Karsho Platform and
    Services is as provided in Karsho’s Privacy Policy located at 
    <a href="https://www.karsho.com/privacy" target="_blank" rel="noopener noreferrer">
      www.karsho.com/privacy
    </a>. You are solely responsible for your Information and your interactions 
    with other members of the public, and we act only as a passive conduit for 
    your online posting of your Information.
  </p> */}
  <p className="terms-paragraph">
    You agree to provide and maintain accurate, current, and complete
    information, and that we and other members of the public may rely on your 
    Information as accurate, current, and complete.
  </p>
</div>

      {/* More sections... (similar structure) */}

      {/* <div className="terms-section">
        <h3 className="terms-subheading">Charges & Subscription</h3>
        <p className="terms-paragraph">
          As a User, you agree to pay the amounts charged for your use of the
          Karsho Platform and Services (“Charges”). Charges include Fares and
          other applicable fees, tolls, surcharges, and taxes as set forth in
          your market’s Karsho page (www.karsho.com).
        </p>
      </div>

      <div className="terms-section">
        <h3 className="terms-subheading">Fees and Other Charges</h3>
        <ul className="terms-list">
          <li>
            <strong>Service Fee:</strong> Karsho may assess a per-park or
            per-event “Service Fee” to support the Karsho Platform and related
            services.
          </li>
        </ul>
      </div> */}
      <div className="terms-section">
  <h3 className="terms-subheading">User-Created Events</h3>
  <p className="terms-paragraph">
    For any event created by users, Karsho reserves the right to cancel,
    deactivate and/or delete the event for any reason Karsho sees fit. These
    reasons may include and are not limited to:
  </p>
  <ul className="terms-list">
    <li className="terms-paragraph">Inappropriate content</li>
    <li className="terms-paragraph">
      Events that can or might be illegal in nature or promote illegal activity
    </li>
    <li className="terms-paragraph">
      Events that use the Karsho platform to redirect Karsho users to third-party
      registration platforms as a means to solicit user information and data.
    </li>
  </ul>
  <p className="terms-paragraph">
    "Official Karsho event" ("Karsho Event") does not constitute an event
    organized by Karsho, nor does it indicate Karsho having any legal
    representation or assume any liability with said event. The label "Official
    Event" is used for marketing purposes only.
  </p>
</div>
<div className="terms-section">
  <h3 className="terms-subheading">Promotions and Referral Programs</h3>
  <p className="terms-paragraph">
    Karsho, at its sole discretion, may make available promotions with
    different features to any Users or prospective Users. These promotions,
    unless made to you, shall have no bearing whatsoever on your Agreement or
    relationship with Karsho. Karsho reserves the right to withhold or deduct
    credits or benefits obtained through a promotion in the event that Karsho
    determines or believes that the redemption of the promotion or receipt of
    the credit or benefit was in error, fraudulent, illegal, or in violation of
    the applicable promotion terms or this Agreement.
  </p>
  <p className="terms-paragraph">
    From time to time, Karsho may offer you incentives to refer new Users to
    the Karsho community (the “Referral Program”). These incentives may come in
    the form of Karsho REP, and Karsho may set or change the incentive types,
    amounts, terms, restrictions, and qualification requirements for any
    incentives in its sole discretion. Your distribution of Invite Codes and
    participation in the Referral Program is subject to this Agreement and the
    additional Referral Program Rules.
  </p>
</div>
<div className="terms-section">
  <h3 className="terms-subheading">Restricted Activities</h3>
  <p className="terms-paragraph">
    With respect to your use of the Karsho Platform and your participation in
    the Services, you agree that you will not:
  </p>
  <ul className="terms-list">
    <li className="terms-list-item">Impersonate any person or entity;</li>
    <li className="terms-list-item">
      Stalk, threaten, or otherwise harass any person, or carry any weapons;
    </li>
    <li className="terms-list-item">
      Violate any law, statute, rule, permit, ordinance, or regulation;
    </li>
    <li className="terms-list-item">
      Interfere with or disrupt the Services or the Karsho Platform or the
      servers or networks connected to the Karsho Platform;
    </li>
    <li className="terms-list-item">
      Post Information or interact on the Karsho Platform or Services in a
      manner which is false, inaccurate, misleading (directly or by omission or
      failure to update information), defamatory, libelous, abusive, obscene,
      profane, offensive, sexually oriented, threatening, harassing, or illegal;
    </li>
    <li className="terms-list-item">
      Use the Karsho Platform in any way that infringes any third party’s
      rights, including but not limited to intellectual property rights,
      copyright, patent, trademark, trade secret, or other proprietary rights or
      rights of publicity or privacy;
    </li>
    <li className="terms-list-item">
      Post, email, or otherwise transmit any malicious code, files, or programs
      designed to interrupt, damage, destroy, or limit the functionality of any
      computer software or hardware or telecommunications equipment or
      surreptitiously intercept or expropriate any system, data, or personal
      information;
    </li>
    <li className="terms-list-item">
      Forge headers or otherwise manipulate identifiers to disguise the origin
      of any information transmitted through the Karsho Platform;
    </li>
    <li className="terms-list-item">
      "Frame" or "mirror" any part of the Karsho Platform without our prior
      written authorization or use meta tags or code or other devices containing
      any reference to us to direct any person to any other website for any
      purpose; or
    </li>
    <li className="terms-list-item">
      Modify, adapt, translate, reverse engineer, decipher, decompile, or
      otherwise disassemble any portion of the Karsho Platform or any software
      used on or for the Karsho Platform.
    </li>
  </ul>
</div>
<div className="terms-section">
  <h3 className="terms-subheading">
    Subscriber Representations, Warranties, and Agreements
  </h3>
  <p className="terms-paragraph">
    By using the Services as a Subscriber on the Karsho Platform, you represent,
    warrant, and agree that:
  </p>
  <ul className="terms-list">
    <li className="terms-list-item">
      You are 18 or older and possess a valid legal driver’s license.
    </li>
    <li className="terms-list-item">
      You own, or have the legal right to operate, the vehicle registered under
      the Karsho Application or website.
    </li>
    <li className="terms-list-item">
      You will only use those vehicles that have been reported to, and approved
      by Karsho, and for which a photograph has been provided to Karsho.
    </li>
  </ul>
</div>

<div className="terms-section">
  <h3 className="terms-subheading">Intellectual Property</h3>
  <p className="terms-paragraph">
    All intellectual property rights in the Karsho Platform shall be owned by
    Karsho LLC and Vivid Koncepts Design Studio LLC absolutely and in their entirety. These
    rights include but are not limited to database rights, copyright, design
    rights (whether registered or unregistered), trademarks (whether registered
    or unregistered), and other similar rights wherever existing in the world
    together with the right to apply for protection of the same.
  </p>
  <p className="terms-paragraph">You agree that you will not:</p>
  <ul className="terms-list">
    <li className="terms-list-item">
      Create any materials that incorporate the Karsho Marks or any derivatives
      of the Karsho Marks other than as expressly approved by Karsho in writing;
    </li>
    <li className="terms-list-item">
      Use the Karsho Marks in any way that tends to impair their validity as
      proprietary trademarks, service marks, trade names, or trade dress;
    </li>
    <li className="terms-list-item">
      Take any other action that would jeopardize or impair Karsho’s rights as
      owner of the Karsho Marks.
    </li>
  </ul>
</div>

<div className="terms-section">
  <p className="terms-paragraph">
    Violation of any provision of this License may result in immediate
    termination of the License.
  </p>
</div>

      {/* <div className="terms-section">
        <h3 className="terms-subheading">General</h3>
        <ul className="terms-list">
          <li>
            <strong>Facilitation of Charges:</strong> All Charges are
            facilitated through a third-party payment processing service.
          </li>
          <li>
            <strong>No Refunds:</strong> All Charges are non-refundable.
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default Terms;
