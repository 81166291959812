import React, { useState } from "react";
import {
  IoPersonOutline,
  IoMailOutline,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoCloseOutline,
  IoAddOutline,
} from "react-icons/io5";
import "./FormModal.css";
import TermsModal from "./TermsModal";
import { apiFetch } from "./apiFetch"; // Import the apiFetch function

const FormModal = ({ formType, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    file: null,
    media_caption: "",
  });
  const [previewMedia, setPreviewMedia] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [emailStatus, setEmailStatus] = useState(null);
  const [usernameStatus, setUsernameStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const handleOpenTerms = () => {
    setIsTermsOpen(true);
  };

  const handleCloseTerms = () => {
    setIsTermsOpen(false);
  };

  const validateEmail = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setEmailStatus("invalid");
      return;
    }

    try {
      const response = await apiFetch(`/user/exists/email/${formData.email}`);
      setEmailStatus(!response.ok ? "valid" : "invalid");
    } catch (error) {
      setEmailStatus("invalid");
      console.error("Error checking email:", error);
    }
  };

  const validateUsername = async () => {
    if (formData.username.length < 3) {
      setUsernameStatus("invalid");
      return;
    }

    try {
      const response = await apiFetch(`/user/exists/username/${formData.username}`);
      setUsernameStatus(!response.ok ? "valid" : "invalid");
    } catch (error) {
      setUsernameStatus("invalid");
      console.error("Error checking username:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("username", formData.username);
    data.append("media_caption", formData.media_caption);

    if (formData.file) {
      data.append("media", formData.file);
    }

    try {
      const result = await apiFetch(`/submissions`, "POST", data);
      console.log("Form submitted successfully:", result);
      onClose();
      alert("Thanks for uploading! You will receive an email once Karsho goes live with your content.");
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmitInviteOrNotify = async (submissionType) => {
    setIsLoading(true);
  
    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("submission_type", submissionType); // Invite or notify
  
    try {
      const result = await apiFetch(`/submissions`, "POST", data);
      console.log("Request submitted successfully:", result);
      onClose();
      alert(
        `You're high on the waitlist. You will receive an email notification once Karsho goes live.`
      );
    } catch (error) {
      console.error("Error submitting the request:", error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "username") {
      // Ensure username is valid: no spaces, only alphanumeric characters, ., -, and _
      const validUsername = value.replace(/[^a-zA-Z0-9._-]/g, "").slice(0, 20);
      setFormData((prevState) => ({ ...prevState, [name]: validUsername }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({ ...prevState, file }));

    // Create a preview URL for the uploaded media
    const mediaPreview = URL.createObjectURL(file);
    setPreviewMedia(mediaPreview);
  };

  const handleCaptionChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      media_caption: e.target.value,
    }));
  };

  const removePreviewMedia = () => {
    setFormData({ ...formData, file: null });
    setPreviewMedia(null);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen); // Toggle fullscreen modal
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <button
            className="close-button"
            onClick={onClose}
            disabled={isLoading}
          >
            <IoCloseOutline size={24} />
          </button>
          {formType === "upload" ? (
            <>
              <h3>Start Your Build</h3>
              <form onSubmit={handleSubmit}>
                {/* Form inputs for uploading content */}
                <div className="input-container">
                  <IoMailOutline className="icon" />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={validateEmail} // Call validation on blur
                    required
                    disabled={isLoading}
                  />
                  {emailStatus === "valid" && (
                    <IoCheckmarkCircleOutline className="status-icon valid" />
                  )}
                  {emailStatus === "invalid" && (
                    <IoCloseCircleOutline className="status-icon invalid" />
                  )}
                </div>

                <div className="input-container">
                  <IoPersonOutline className="icon" />
                  <input
                    type="text"
                    name="name"
                    placeholder="What is your name?"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    disabled={isLoading}
                  />
                </div>
                <div className="input-container">
                  @
                  <input
                    type="text"
                    name="username"
                    placeholder="Claim your username"
                    value={formData.username}
                    onChange={handleInputChange}
                    onBlur={validateUsername} // Call validation on blur
                    required
                    disabled={isLoading}
                  />
                  {usernameStatus === "valid" && (
                    <IoCheckmarkCircleOutline className="status-icon valid" />
                  )}
                  {usernameStatus === "invalid" && (
                    <IoCloseCircleOutline className="status-icon invalid" />
                  )}
                </div>

                <p className="label-left">
                  You will receive a temporary password to login and reset when
                  Karsho goes live.
                </p>
                {!previewMedia && (
                  <>
                    <p className="terms-label">
                      Choose the best single visual of your car, truck, or bike.
                      Keep videos to one minute or less.
                    </p>
                    <label className="custom-upload-button">
                      <input
                        type="file"
                        name="file"
                        accept="image/*,video/*"
                        onChange={handleFileChange}
                        required
                        style={{ display: "none" }} // Hide the default input
                        disabled={isLoading}
                      />
                      <IoAddOutline size={30} color="white" />
                    </label>
                  </>
                )}
                {previewMedia && (
                  <>
                    <div className="media-preview-container">
                      <div className="media-preview" onClick={toggleFullScreen}>
                        {formData.file &&
                        formData.file.type.startsWith("video") ? (
                          <video
                            src={previewMedia}
                            controls={false}
                            loop
                            autoPlay
                            muted
                            width="100%"
                          />
                        ) : (
                          <img src={previewMedia} alt="Preview" width="100%" />
                        )}
                      </div>
                      <button
                        className="remove-preview"
                        onClick={removePreviewMedia}
                        disabled={isLoading}
                      >
                        <IoCloseOutline size={24} />
                      </button>
                    </div>
                    <input
                      type="text"
                      value={formData.media_caption}
                      onChange={handleCaptionChange}
                      placeholder="Tap to add a caption to your visual..."
                      className="caption-input"
                      disabled={isLoading}
                    />
                  </>
                )}
                <button
                  type="submit"
                  disabled={
                    isLoading ||
                    emailStatus === "invalid" ||
                    usernameStatus === "invalid" ||
                    !previewMedia
                  }
                >
                  {isLoading ? "Submitting..." : "Upload to Karsho"}
                </button>
                <p className="terms-label">
                  By uploading, you agree to the{" "}
                  <button
                    type="button"
                    className="terms-link"
                    onClick={handleOpenTerms} // Open the terms modal
                  >
                    terms and conditions
                  </button>{" "}
                  of Karsho.
                </p>
              </form>
            </>
          ) : (
            <>
              <p className="karsho-description">
                <b>Karsho is where the heart of car culture thrives.</b>
              </p>
              <p className="karsho-description">
                Built for true automotive enthusiasts, it’s more than just a
                platform—it’s a global community where passion meets innovation.
              </p>
              <form>
                <div className="input-container">
                  <IoPersonOutline className="icon" />
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    disabled={isLoading}
                  />
                </div>
                <div className="input-container">
                  <IoMailOutline className="icon" />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={validateEmail} // Validate email on blur
                    required
                    disabled={isLoading}
                  />
                  {emailStatus === "valid" && (
                    <IoCheckmarkCircleOutline className="status-icon valid" />
                  )}
                  {emailStatus === "invalid" && (
                    <IoCloseCircleOutline className="status-icon invalid" />
                  )}
                </div>
                <button
                  type="submit"
                  disabled={
                    isLoading ||
                    emailStatus === "invalid" || // Disable if email is invalid
                    !formData.name // Disable if name is empty
                  }
                  onClick={() => handleSubmitInviteOrNotify("invite")}
                >
                  {isLoading ? "Submitting..." : "Request Invite Code"}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
      {isTermsOpen && <TermsModal onClose={handleCloseTerms} />}
      {isFullScreen && (
        <div className="fullscreen-modal" onClick={toggleFullScreen}>
          <button className="close-fullscreen">
            <IoCloseOutline size={36} />
          </button>
          {formData.file && formData.file.type.startsWith("video") ? (
            <video
              src={previewMedia}
              controls
              loop
              autoPlay
              muted
              className="fullscreen-media"
            />
          ) : (
            <img
              src={previewMedia}
              alt="Fullscreen Preview"
              className="fullscreen-media"
            />
          )}
        </div>
      )}
    </>
  );
};

export default FormModal;
