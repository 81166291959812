// apiFetch.js
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const apiFetch = async (url, method = "GET", body = null) => {
  const jwtToken = 'xxx' // Function to get the JWT token (from storage or context)
  const options = {
    method,
    headers: {
        "Authorization": `Bearer ${jwtToken} ApiKey ${API_KEY}`,  // Include both tokens here
    },
  };

  // Check if the body is FormData; otherwise, handle it as JSON
  if (body) {
    if (body instanceof FormData) {
      options.body = body; // FormData doesn't need Content-Type header
    } else {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(body);
    }
  }

  try {
    const response = await fetch(`${API_BASE_URL}${url}`, options);

    if (!response.ok) {
      throw new Error(`Error ${method} request to ${url}: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("API fetch error:", error);
    throw error;
  }
};
