import React, { useRef, useEffect } from "react";

const BackgroundVideo = ({ isPlaying }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const ensurePlay = () => {
      if (videoElement && videoElement.paused) {
        videoElement.play().catch(error => console.error("Playback error:", error));
      }
    };

    // Trigger play when component mounts or when `isPlaying` changes
    if (isPlaying && videoElement) {
      ensurePlay();
    }

    // Set up an interval to ensure the video keeps playing
    const playInterval = setInterval(() => {
      if (videoElement && videoElement.paused) {
        videoElement.play().catch(error => console.error("Playback error:", error));
      }
    }, 1000); // Retry every second if paused

    // Event listener to immediately play if video is paused
    videoElement.addEventListener("pause", ensurePlay);

    return () => {
      clearInterval(playInterval);
      if (videoElement) {
        videoElement.removeEventListener("pause", ensurePlay);
      }
    };
  }, [isPlaying]);

  return (
    <div className="video-background">
      <video ref={videoRef} loop muted playsInline controls={false} autoPlay>
        <source
          src="https://cdn.karsho.com/submissions/ab-6ad4d4f2-9126-11ef-a6fe-0242c0a81003.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BackgroundVideo;
